.chart-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  z-index: 999;
}
.chart-title {
  font-size: 30px;
  font-weight: 900;
  color: #fff;
  padding: 20px;
}
.bar-container {
  display: flex;
  flex-direction: column;
}

.bar-label {
  font-weight: bold;
  color: #ffffff9f;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 40px;
  border: 1px dashed #ffffff9f;
  border-radius: 5px;
}

.bar {
  display: flex;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.bar-segment {
  height: 100%;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
}

.bar-segment.hovered {
  transform: scaleY(2.1);
  z-index: 999; /* Ensure the hovered segment is on top */
}
.details {
  margin-bottom: 5%;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 20px;
}
.legend-item {
  display: flex;
  padding-bottom: 5px;
  padding-bottom: 5px;
  padding-left: 10%;
}
/* mobile css */

@media (max-width: 768px) {
  .details {
    margin-left: 10px;
    margin-right: 10px;
  }
  .legend-item {
    display: flex;
    padding-left: 10%;
    padding-bottom: 5px;
  }
}
