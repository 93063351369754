/*
=====
UIA-TIMELINE
=====
*/

.uia-timeline__container {
  display: var(--uia-timeline-display, grid);
}

.uia-timeline__groups {
  display: var(--uia-timeline-groups-display, grid);
  gap: var(--uia-timeline-groups-gap, 1rem);
}

/*
  SKIN 1
  */

[data-uia-timeline-skin="skin-1"] .uia-timeline__step {
  line-height: 1;
  font-size: var(--uia-timeline-step-font-size, 2rem);
  font-weight: var(--uia-timeline-step-font-weight, 700);
  color: var(--uia-timeline-step-color);
}

[data-uia-timeline-skin="skin-1"] .uia-timeline__point-intro {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: var(--uia-timeline-point-intro-gap, 0.5rem);
}

[data-uia-timeline-skin="skin-1"] .uia-timeline__point-date {
  grid-row: 1;
  grid-column: 2;
}

[data-uia-timeline-skin="skin-1"] .uia-timeline__point-heading {
  grid-column: span 2;
}

[data-uia-timeline-skin="skin-1"] .uia-timeline__point-description {
  margin-block-start: var(--uia-timeline-group-gap, 1rem);
  inline-size: min(100%, var(--uia-timeline-content-max-limit));
}

/*
  SKIN 2
  */

[data-uia-timeline-skin="2"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-minimal-gap: var(--uia-timeline-minimal-gap, 0.5rem);
  --_uia-timeline-space: calc(
    var(--_uia-timeline-arrow-size) + var(--_uia-timeline-dot-size) + var(--_uia-timeline-dot-size) / 2 + var(--_uia-timeline-minimal-gap)
  );
  --_uia-timeline-dot-size: var(--uia-timeline-dot-size, 1rem);
  --_uia-timeline-arrow-size: var(--uia-timeline-arrow-size, 0.25rem);
  --_uia-timeline-arrow-position: var(--uia-timeline-arrow-position, 1rem);
}

[data-uia-timeline-skin="2"] .uia-timeline__container {
  position: relative;
  padding-inline-start: calc(var(--_uia-timeline-space));
}

[data-uia-timeline-skin="2"] .uia-timeline__line {
  inline-size: var(--uia-timeline-line-thickness, 3px);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: calc(var(--_uia-timeline-dot-size) / 2);
  transform: translate(-50%);
}

[data-uia-timeline-skin="2"] .uia-timeline__group {
  position: relative;
}

[data-uia-timeline-skin="2"] .uia-timeline__dot {
  box-sizing: border-box;
  inline-size: var(--_uia-timeline-dot-size);
  block-size: var(--_uia-timeline-dot-size);

  border-radius: 50%;
  border: var(--uia-timeline-dot-border-thickness, 1px) solid var(--uia-timeline-dot-border-color, var(--_uia-timeline-line-color_default));
  background-color: var(--uia-timeline-dot-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  /* - 4px is used for set the default gap from the top border */
  inset-block-start: calc(var(--uia-timeline-dot-position, var(--_uia-timeline-arrow-position)) + 4px);
  inset-inline-start: calc(-1 * var(--_uia-timeline-space));
}

[data-uia-timeline-skin="2"] .uia-timeline__point {
  position: relative;
  background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="2"] .uia-timeline__point::before {
  content: "";
  inline-size: 0;
  block-size: 0;

  border: var(--_uia-timeline-arrow-size) solid var(--uia-timeline-arrow-color, var(--_uia-timeline-line-color_default));
  border-block-start-color: transparent;
  border-inline-end-color: transparent;

  position: absolute;
  /* - 6px is used for set the default gap from the top border */
  inset-block-start: calc(var(--_uia-timeline-arrow-position) + 6px);
  inset-inline-start: calc(-1 * var(--_uia-timeline-arrow-size) + 1px);
  transform: rotate(45deg);
}

[data-uia-timeline-adapter-skin-2="ui-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness: var(--uia-timeline-point-border-thickness, 3px);
  --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));
  --uia-card-background-color: var(--uia-timeline-point-background-color);
}

/*
  SKIN 3
  */

[data-uia-timeline-skin="3"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-space: var(--uia-timeline-space, 1rem);
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
  --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__container {
  position: relative;
  gap: var(--uia-timeline-annual-sections-gap, 2.5rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__line {
  inline-size: var(--_uia-timeline-line-thickness);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

[data-uia-timeline-skin="3"] .uia-timeline__annual-sections {
  display: grid;
  gap: 2rem;
}

[data-uia-timeline-skin="3"] .uia-timeline__groups {
  padding-inline-start: var(--_uia-timeline-space);
}

[data-uia-timeline-skin="3"] .uia-timeline__group {
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="3"] .uia-timeline__point {
  background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="3"] .uia-timeline__point::before {
  content: "";
  inline-size: 100%;
  block-size: var(--_uia-timeline-line-thickness);
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--_uia-timeline-point-line-position);
  inset-inline-start: calc(-1 * var(--_uia-timeline-space));
  z-index: -1;
}

[data-uia-timeline-skin="3"] .uia-timeline__year {
  inline-size: fit-content;
  padding: var(--uia-timeline-year-padding, 0.25rem 0.75rem);
  background-color: var(--uia-timeline-year-background-color, var(--_uia-timeline-line-color_default));
  color: var(--uia-timeline-year-color, #b22234);
}

[data-uia-timeline-adapter-skin-3="uia-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness: var(--uia-timeline-point-border-thickness, 3px);
  --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));
  --uia-card-background-color: var(--uia-timeline-point-background-color);
}

/*
  SKIN 4
  */

[data-uia-timeline-skin="4"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-space: var(--uia-timeline-space, 0.5rem);
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
  --_uia-timeline-annual-sections-safe-gap: var(--uia-timeline-annual-sections-safe-gap, 1.5rem);
  --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
  --_uia-timeline-year-size: var(--uia-timeline-year-size, 3.5rem);
}

[data-uia-timeline-skin="4"] .uia-timeline__container {
  position: relative;
  gap: var(--uia-timeline-annual-sections-gap, 2.5rem);
}

[data-uia-timeline-skin="4"] .uia-timeline__line {
  inline-size: var(--_uia-timeline-line-thickness);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: calc(var(--_uia-timeline-year-size) / 2);
  transform: translate(-50%);
  z-index: -1;
}

[data-uia-timeline-skin="4"] .uia-timeline__annual-sections {
  display: flex;
  align-items: flex-start;
  isolation: isolate;
}

[data-uia-timeline-skin="4"] .uia-timeline__groups {
  padding-inline-start: var(--_uia-timeline-space);
  padding-block-start: calc(var(--_uia-timeline-year-size) + var(--_uia-timeline-annual-sections-safe-gap));
}

[data-uia-timeline-skin="4"] .uia-timeline__group {
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="4"] .uia-timeline__point {
  background-color: var(--uia-timeline-point-background-color, white);
  color: #fff;
}

[data-uia-timeline-skin="4"] .uia-timeline__point::before {
  content: "";
  inline-size: 100%;
  block-size: var(--_uia-timeline-line-thickness);
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--_uia-timeline-point-line-position);
  inset-inline-start: calc(-1 * (var(--_uia-timeline-space) + var(--_uia-timeline-year-size) / 2));
  z-index: -1;
}

[data-uia-timeline-skin="4"] .uia-timeline__year {
  box-sizing: border-box;
  flex: none;
  inline-size: var(--_uia-timeline-year-size);
  block-size: var(--_uia-timeline-year-size);

  border: var(--uia-timeline-year-line-thickness, var(--_uia-timeline-line-thickness)) var(--uia-timeline-year-line-style, solid)
    var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));
  border-radius: 50%;

  display: grid;
  place-items: center;
  background-color: var(--uia-timeline-year-background-color, #b22234);

  font-size: var(--uia-timeline-year-font-size, 0.75rem);
  color: var(--uia-timeline-year-color, #222);
}

[data-uia-timeline-adapter-skin-4="uia-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness: var(--uia-timeline-point-border-thickness, 3px);
  --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));
  --uia-card-background-color: var(--uia-timeline-point-background-color);
}

/*
  SKIN 5
  */

[data-uia-timeline-skin="5"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 3px);
  --_uia-timeline-line-calculation: calc(var(--_uia-timeline-dot-size) / 2 - calc(var(--_uia-timeline-line-thickness) / 2));
  --_uia-timeline-line-position: var(--uia-timeline-line-position, 0px);

  --_uia-timeline-minimal-gap: var(--uia-timeline-minimal-gap, 0.5rem);
  --_uia-timeline-space: calc(var(--_uia-timeline-dot-size) + var(--_uia-timeline-dot-size) / 2 + var(--_uia-timeline-minimal-gap));

  --_uia-timeline-dot-size: var(--uia-timeline-dot-size, 1rem);
}

[data-uia-timeline-skin="5"] .uia-timeline__container {
  position: relative;
  padding-inline-start: var(--_uia-timeline-space);
}

[data-uia-timeline-skin="5"] .uia-timeline__line {
  border-inline-start: var(--_uia-timeline-line-thickness) solid var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));
  block-size: calc(100% - var(--_uia-timeline-line-position));

  position: absolute;
  inset-block-start: var(--_uia-timeline-line-position);
  inset-inline-start: var(--uia-timeline-line-main-position, var(--_uia-timeline-line-calculation));
}

[data-uia-timeline-skin="5"] .uia-timeline__group {
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="5"] .uia-timeline__dot {
  box-sizing: border-box;
  font-size: var(--_uia-timeline-dot-size);

  border-radius: 50%;
  box-shadow: 0 0 0 var(--uia-timeline-dot-border-thickness, 1px) var(--uia-timeline-dot-border-color, var(--_uia-timeline-line-color_default));
  border: 0.5em solid var(--uia-timeline-dot-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--uia-timeline-dot-position, -1px);
  inset-inline-start: var(--uia-timeline-dot-main-position, calc(-1 * var(--_uia-timeline-space)));
}

[data-uia-timeline-skin="5"] .uia-timeline__group:last-child::after {
  content: "";
  inline-size: calc(var(--_uia-timeline-line-thickness) + var(--uia-timeline-line-mask-thickness, 4px));
  block-size: calc(100% - var(--_uia-timeline-line-position) - var(--_uia-timeline-dot-size) / 2);
  background-color: var(--uia-timeline-line-mask-color);
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: var(--_uia-timeline-line-mask-main-position, calc((var(--_uia-timeline-space) - var(--_uia-timeline-dot-size) / 2) * -1));
  transform: translate(-50%);
  z-index: -1;
}

[data-uia-timeline-skin="5"] {
  container-type: inline-size;
  container-name: timeline-skin-5;
}

@container timeline-skin-5 (min-width: 600px) {
  [data-uia-timeline-skin="5"] .uia-timeline__container {
    --uia-timeline-line-main-position: calc(50% - (var(--uia-timeline-line-thickness) / 2));
    --uia-timeline-dot-main-position: 50%;
    --_uia-timeline-space: 0;
  }

  [data-uia-timeline-skin="5"] .uia-timeline__point {
    max-width: 45%;
  }

  [data-uia-timeline-skin="5"] .uia-timeline__group:nth-child(even) .uia-timeline__point {
    margin-inline-start: auto;
  }

  [data-uia-timeline-skin="5"] .uia-timeline__group:nth-child(odd) .uia-timeline__point {
    text-align: end;
  }

  [data-uia-timeline-skin="5"] .uia-timeline__dot {
    transform: translateX(-50%);
  }

  [data-uia-timeline-skin="5"] .uia-timeline__group:last-child::after {
    --_uia-timeline-line-mask-main-position: var(--uia-timeline-line-main-position);
  }
}

[data-uia-timeline-adapter-skin-5="uia-card"] {
  --uia-card-content-gap: var(--uia-timeline-content-gap, 0.5rem);
}

/*
  =====
  RESET
  =====
  */

:where(.ra-link) {
  display: var(--ra-link-display, inline-flex);
}

:where(.ra-link[href]) {
  color: var(--ra-link-color, inherit);
  text-decoration: var(--ra-link-text-decoration, none);
}

:where(.ra-heading) {
  margin-block-start: var(--ra-heading-margin-block-start, 0);
  margin-block-end: var(--ra-heading-margin-block-end, 0);
}

/*
  =====
  HELPERS
  =====
  */

.ha-screen-reader {
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip-path: var(--ha-screen-reader-clip-path, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
  =====
  UIA-CARD
  =====
  */

.uia-card__container {
  display: var(--uia-card-display, grid);
  gap: var(--uia-card-content-gap);
  /* width */
}

.uia-card__time-divider::before {
  content: "—";
  margin-inline: var(--uia-card-time-divider-margin-inline, 0.15rem);
}

[data-uia-card-skin="1"] .uia-card__container {
  padding: var(--uia-card-padding, 1rem 1.75rem);
  background-color: var(--uia-card-background-color);
  border-radius: var(--uia-card-border-radius, 2px);
}

[data-uia-card-skin="1"] .uia-card__intro {
  display: grid;
  gap: var(--uia-card-intro-gap, 1rem);
}

[data-uia-card-skin="1"] .uia-card__time {
  grid-row: 1 / 1;
  inline-size: fit-content;
  padding: var(--uia-card-time-padding, 0.25rem 1.25rem 0.25rem);
  background-color: var(--uia-card-time-background-color, #b22234);

  font-weight: var(--uia-card-time-font-weight, 700);
  font-size: var(--uia-card-time-font-size, 0.75rem);
  text-transform: var(--uia-card-time-text-transform, uppercase);
  color: var(--uia-card-time-color, currentColor);
}

[data-uia-card-skin="1"][data-uia-card-mod="1"] .uia-card__container {
  border-inline-start: var(--uia-card-border-thickness, 2px) var(--uia-card-border-style, solid) var(--uia-card-border-color, currentColor);
  box-shadow: var(--uia-card-box-shadow, 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24));
}

/*
  =====
  UIA-CONTROL
  =====
  */

.uia-control__group {
  cursor: var(--uia-control-cursor, var(--_uia-control-cursor, pointer));
  line-height: var(--uia-control-line-height, 1);
}

.uia-control {
  --_uia-control-padding_default: 0;

  --_uia-control-border-width: var(--uia-control-border-width);
  --_uia-control-border-style: var(--uia-control-border-style, solid);
  --_uia-control-border-color: var(--uia-control-border-color, currentColor);

  --_uia-control-padding: var(
    --uia-control-padding,
    var(--uia-control-padding-block-start, var(--_uia-control-padding_default))
      var(--uia-control-padding-inline-end, var(--_uia-control-padding_default))
      var(--uia-control-padding-block-end, var(--_uia-control-padding_default))
      var(--uia-control-padding-inline-start, var(--_uia-control-padding_default))
  );
  --_uia-control-border: var(--_uia-control-border-width) var(--_uia-control-border-style) var(--_uia-control-border-color);
  --_uia-control-border-radius: var(--uia-control-border-radius, 0.25rem);
}

.uia-control__group {
  display: var(--uia-control-display, inline-flex);
  gap: var(--uia-control-gap, 0.5rem);

  padding: var(--_uia-control-padding);

  border: var(--_uia-control-border);
  border-radius: var(--_uia-control-border-radius);
  background-image: linear-gradient(
    var(--uia-control-background-degree, 135deg),
    var(--uia-control-background-start-color, var(--uia-control-background-color)) var(--uia-control-background-start-position, 0),
    var(--uia-control-background-end-color, var(--uia-control-background-color)) var(--uia-control-background-end-position, 100%)
  );
}

/*
  =====
  DEMO
  =====
  */

:root {
  --uia-timeline-line-color: white;
  --uia-timeline-dot-color: white;
  --uia-timeline-arrow-color: white;
  --uia-timeline-line-thickness: 3px;
  --uia-timeline-point-border-color: white;
  --uia-timeline-group-padding: 1.5rem 1.5rem 1.25rem;
}

.timeline-5-2 {
  --uia-timeline-line-mask-color: #b22234;
}

[data-uia-timeline-skin="3"] {
  --uia-timeline-year-background-color: white;
}

.timeline-body {
  color: rgb(222, 222, 222);
  background-color: #b22234;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.timeline-body p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
  color: rgb(222, 222, 222);
}
.timeline-body h1 {
  text-align: center;
}

.page {
  box-sizing: border-box;
  inline-size: min(100%, 90ch);
  padding: 2rem 2rem 3rem;
  margin-inline: auto;
}

.linktr {
  --uia-control-background-color: rgb(209, 246, 255);
  --uia-control-border-radius: 2rem;
  --uia-control-padding: 0.75rem 1.5rem;
  --uia-control-border-width: 1px;
  --uia-control-border-color: rgb(209, 246, 255);

  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal {
  box-shadow: rgb(8 49 112 / 24%) 0 2px 8px 0;
}
