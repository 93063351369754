.logo-foot {
  height: auto;
  width: 200px;
}
.image-container {
  text-align: center;
  margin-bottom: 5%;
}
.footer {
  padding-bottom: 5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.181);
}
.logofooter {
  height: auto;
  width: 60%;
  display: inline-block; /* Makes the image respect text alignment */
}

.footer-container {
  padding: 5%;
  background: white;
  color: black;
}
.footer-container p {
  color: rgba(0, 0, 0, 0.62);
  font-weight: 300;
}

.icon-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  width: 150px;
  cursor: pointer;
  margin-top: 20px;
}
.icon-item a {
  text-decoration: none;
  color: black;
}
.icon {
  height: 20px;
  width: auto;
  padding-right: 5px;
}

.right-grid {
  padding-left: 20% !important;
  border-left: 1px solid black;
  margin-left: 20%;
}
@media (max-width: 768px) {
  .right-grid {
    padding-left: 0% !important;
    border-left: none;
    margin-left: 0%;
  }
}
