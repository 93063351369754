.tokenomics {
  background-color: #1877f2;
  padding-bottom: 5%;
  position: relative;
  padding-top: 10%;
}

.toke-container {
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 99;
  border-radius: 15px;
}

.cont {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  padding-top: 10%;
  padding-bottom: 10%;
}

.buy-tok {
  background-color: white;
  color: black;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5rem;
  cursor: pointer;
  text-align: center;
  width: 50%;
}
.circle {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 20%;
  z-index: 1;
}

.circle2 {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 15%;
  z-index: 1;
}

.img-tok {
  width: 100%;
  height: auto;
  z-index: 999;
  position: relative;
}

/* for mobile device */
@media only screen and (max-width: 600px) {
  .circle {
    width: 50%;
    top: 2%;
    left: 1%;
  }
  .toke-container {
    margin-left: -10px !important;
  }
}
