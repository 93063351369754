.mc_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mc_title {
  height: auto;
  width: 200px;
}

.mc_section {
  background-color: #1877f2;
  padding: 10%;
}
.mc_box {
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin-bottom: 5%;
  border-radius: 20px;
}
.star {
  height: auto;
  width: 80px;
}

.box_title {
  font-family: "Tourney", sans-serif !important;
  color: white;
}
.box-loc {
  display: flex;
  align-items: center;
}

.box-loc img {
  height: 20px;
  width: auto;
}

.img-box {
  width: "100%";
  height: "100%";
}
@media only screen and (max-width: 600px) {
  .img-box {
    height: auto !important;
    width: 100% !important;
  }
}
