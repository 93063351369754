.container {
  background-color: #b22234;
  height: 100px;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
  }
}
