.landing {
  background-color: #1877f2; /* Set the background image */
  background-size: cover; /* Cover the entire viewport */
  height: 105vh;
}
.landing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.867); /* Black color overlay with 50% opacity */
  z-index: -1; /* Ensure it's behind the content */
}

.shiba_landing {
  padding-top: 90px;
  width: 90%;
  height: auto;
}
.title {
  color: white;
  font-size: 7rem;
  line-height: 110%;
  text-align: center;
  padding-top: 10%;
}
.title span {
  color: white;
  font-weight: 200;
}
.btn-title {
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
}
.buy {
  background-color: #b22234;
  color: white;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5rem;
  cursor: pointer;
}
.join_tg {
  color: white;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  margin-left: 20px;
  border-radius: 5rem;
  border: 1px dashed white;
  cursor: pointer;
  text-decoration: none;
}
.join_tg:hover {
  border: 1px solid white;
}
@media (max-width: 768px) {
  .landing {
    height: auto;
    border-bottom: none;
  }
  .title {
    font-size: 5rem;
    padding-top: 0%;
  }
  .btn-title {
    padding-bottom: 50px;
  }
  .shiba_landing {
    width: 100%;
    height: auto;
    padding-top: 100px;
  }
  .join_tg {
    margin-left: 5px;
  }
}
