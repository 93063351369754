#roadmap {
  background-color: #b22234;
  padding-top: 10%;
}
.rd_foot {
  height: auto;
  width: 60%;
  display: inline-block; /* Makes the image respect text alignment */
}

.header {
  font-family: "Tourney", sans-serif !important;
  color: #fff;
  font-size: 30px;
}

.point {
  font-size: 16px;
  color: #ffffffaa;
  text-align: right;
  list-style: none;
}
