.pt_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  overflow-y: scroll;
}
.pt_icon {
  height: auto;
  width: 100px;
  padding: 20px;
}
