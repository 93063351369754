.navbar-container {
  position: fixed;
  top: 20;
  width: 100%;
  background-color: #333;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #b22234 !important;
}
.navbar-container-sticky {
  position: fixed;
  top: 20;
  width: 100%;
  background-color: #333;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #b22234 !important;
}
.nav_desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.logo {
  width: auto;
  height: 70px;
}
p {
  color: white;
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.2s ease;
}

.nav-item:hover {
  color: #f7b500;
  font-weight: 700;
}

.nav-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.nav-toggle span {
  height: 2px;
  width: 25px;
  background: #f7b500;
  margin-bottom: 5px;
  transition: transform 0.3s ease;
}

.nav_responsive {
  display: none;
}
.join_header {
  background-color: white;
  width: 100%;
}

.join_header p {
  color: black;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
}
.marquee-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  animation: marquee 10s linear infinite;
}
.marquee-content > span {
  display: inline-block;
  padding-right: 2rem; /* Small space between items */
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.icon {
  width: 20px;
  height: auto;
}
@media (max-width: 768px) {
  .navbar {
    display: none;
  }
  .nav_responsive {
    display: block;
  }
  .menu_icon {
    display: block;
    color: white;
  }
  .nav_responsive_ul {
    list-style: none;
    padding-top: 10%;
    text-align: center;
  }
  .nav_responsive_ul li a {
    color: white;
    text-decoration: none;
  }
  .nav_responsive_ul li {
    padding: 20px;
  }
}
